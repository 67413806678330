<template>
  <div class="member_content_container">
    <div class="member_content w">
      <div class="member_content_left">
        <div class="title">会员模式 裂变推动</div>
        <div class="question">
          如何最大化的保有老客户，转化新客户？如何精准分析客户潜在消费机会？如何挖掘客户持续消费力，培育客户终身价值？<br /><br />
          针对于这些企业难题，需要有一套完善的会员模式来实现。那么，会员模式如何建立？中小企业如何打造独具特色的会员模式，实现企业快速发展？这一切，还需要《会员模式》课程！
        </div>
        <div class="process">
          <div class="link">
            <div>拓</div>
            <div>留</div>
            <div>锁</div>
            <div>升</div>
          </div>
          <div class="study">
            <span>教</span><span>你</span><span>如</span><span>何</span>
          </div>
          <div class="triangle"></div>
          <div class="methods">
            <div>快速拓客引流客户</div>
            <div>快速留住精准客户</div>
            <div>锁定客户持续消费</div>
            <div>将客户升级为你的股东</div>
          </div>
        </div>
      </div>
      <div class="member_content_right">
        <div class="title">会员模式能帮助你什么?</div>
        <div class="can">
          《会员模式》最终会帮你实现：<br />
          1.从投资转向融资做生意，让你不花一分钱开店。<br />
          2.从重资产运营转向轻资产运营。 <br />
          3.把公司越做越小，把市场越做越大。<br />
        </div>
        <div class="ace_lecturer">课程金牌讲师</div>
        <div class="content">
          <div class="process">
            <ul>
              <li>
                <div class="process_number">ONE</div>
                <div class="process_title">
                  财富裂变
                </div>
              </li>
              <li>
                <div class="process_number">TWO</div>
                <div class="process_title">
                  人才裂变
                </div>
              </li>
              <li>
                <div class="process_number">THREE</div>
                <div class="process_title">
                  渠道裂变
                </div>
              </li>
              <li>
                <div class="process_number">FOUR</div>
                <div class="process_title">
                  时间裂变
                </div>
              </li>
              <li>
                挖掘你的潜能，用销讲的魅力征服世界，让你不仅能“讲”还能“销”
              </li>
            </ul>
          </div>
          <div class="lecturer">
            <div class="lecturer_avatar">
              <img src="@/assets/images/member/yyc.png" alt="" />
            </div>
            <div class="lecturer_name">杨雨辰</div>
            <div class="lecturer_identity">
              <div>泉邦集团联合创始人</div>
              <div>会员模式主讲老师</div>
              <div>盈利模式设计专家</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemberContent'
}
</script>

<style lang="less" scoped>
.member_content {
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 44px 0px 217px 0px;
}

.member_content_left {
  width: 756px;
  height: 937px;
  margin-right: 47px;
  padding-left: 36px;
  padding-right: 27px;
  .title {
    font-size: 32px;
    font-weight: 500;
    color: #010343;
    margin-top: 43px;
    margin-bottom: 21px;
  }
  .question {
    font-size: 24px;
    font-weight: 300;
    color: #010343;
    line-height: 48px;
  }
  .process {
    display: flex;
  }
  .link {
    display: flex;
    flex-direction: column;
    div {
      width: 85px;
      height: 85px;
      background: #1651dd;
      font-size: 32px;
      font-weight: 600;
      line-height: 85px;
      text-align: center;
      color: #ffffff;
      margin-top: 27px;
      margin-right: 29px;
    }
  }
  .study {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 421px;
    background: #f7f9fd;
    font-size: 32px;
    font-weight: 600;
    color: #010343;
    margin-top: 27px;
    span {
      margin-bottom: 18px;
    }
  }
  .triangle {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-left: 16px solid #1651dd;
    border-bottom: 30px solid transparent;
    margin-top: 200px;
    margin-left: 29px;
  }
  .methods {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    div {
      width: 395px;
      height: 85px;
      background: #f7f9fd;
      margin-top: 27px;
      line-height: 85px;
      padding-left: 31px;
      font-size: 32px;
      font-weight: 400;
      color: #010343;
    }
  }
}
.member_content_right {
  width: 756px;
  height: 937px;
  padding-top: 43px;
  padding-left: 31px;
  .title {
    font-size: 32px;
    font-weight: 500;
    color: #010343;
    margin-bottom: 21px;
  }
  .can {
    width: 694px;
    height: 240px;
    font-size: 24px;
    font-weight: 400;
    color: #010343;
    line-height: 48px;
  }
  .ace_lecturer {
    font-size: 24px;
    font-weight: 500;
    color: #010343;
  }
  .content {
    display: flex;
  }
  .process {
    li {
      display: flex;
      margin-top: 21px;
      &:nth-child(5) {
        padding: 31px 29px 40px 15px;
        width: 457px;
        height: 145px;
        background: #1651dd;
        font-size: 24px;
        font-weight: 600;
        color: #ffffff;
        line-height: 37px;
        text-align: center;
      }
    }
    .process_number {
      width: 67px;
      height: 67px;
      background: #1651dd;
      font-size: 19px;
      font-weight: 500;
      color: #ffffff;
      line-height: 67px;
      text-align: center;
    }
    .process_title {
      width: 381px;
      height: 67px;
      line-height: 67px;
      text-align: center;
      background: #f7f9fd;
      font-size: 24px;
      font-weight: 500;
      color: #010343;
    }
  }
  .lecturer {
    display: flex;
    flex-direction: column;
    margin-top: 23px;
    margin-left: 23px;
    .lecturer_avatar {
      width: 224px;
      height: 230px;
    }
    .lecturer_name {
      width: 224px;
      height: 45px;
      line-height: 45px;
      background: #1651dd;
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      margin-top: 15px;
    }
    .lecturer_identity {
      width: 224px;
      height: 205px;
      background: #f7f9fd;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      font-size: 19px;
      font-weight: 400;
      color: #010343;
      line-height: 27px;
    }
  }
}
</style>
