<template>
  <div class="member_container">
    <!-- 顶部导航的占位 -->
    <QbTopnavPlaceholder></QbTopnavPlaceholder>

    <!--会员模式标题 -->
    <QbTitle :title="title" :complement="complement"></QbTitle>

    <!-- 会员模式封面图 -->
    <QbCover :src="src"></QbCover>

    <!-- 会员模式课程安排 -->
    <QbSchedule :schedule="schedule"></QbSchedule>

    <!-- 会员模式内容 -->
    <MemberContent></MemberContent>

    <!-- 报名组件 -->
    <QbApplication></QbApplication>
  </div>
</template>

<script>
import MemberContent from './components/member-content.vue'
export default {
  components: {
    MemberContent
  },
  data() {
    return {
      title: '《会员模式》',
      complement: 'Quanbang Group Membership model',
      src: require('@/assets/images/member/member_cover.png'),
      schedule: require('@/assets/images/member/member_schedule.png')
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  background-color: #ffffff;
}
</style>
